import { Image, ImageProps } from 'antd';
import { fallBackImages } from '../../../common/constants';

export default function ImageResult({
  src,
  width = 24,
  alt = '',
  fallback = fallBackImages.pictureChoice,
  ...rest
}: ImageProps) {
  return (
    <Image
      src={src || fallBackImages.pictureChoice}
      width={width}
      alt={alt}
      className="object-contain"
      preview={false}
      {...rest}
    />
  );
}
