import { useLazyQuery } from '@apollo/client';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  ArrowsClockwise,
  DotsSixVertical,
  Trash,
  UploadSimple,
} from '@phosphor-icons/react';
import { Flex, Image, Input, Progress, Upload, UploadProps } from 'antd';
import { map } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../AppContext';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_SIZE,
  fallBackImages,
} from '../../../../../common/constants';
import {
  commonUploadFile,
  conicColors,
  validatePageBackgroundImage,
} from '../../../../../common/utils';
import CommonButton from '../../../../../components/primitives/CommonButton';
import useRouter from '../../../../../hooks/useRouter';
import { AppContextType } from '../../../../../types/appContext.type';
import { Permissions } from '../../../../../types/common.type';
import { ChoiceResponseType, ChoiceType } from '../../../form.types';
import { GET_SIGNED_URL_PICTURE_CHOICE } from '../../../graphql/queries';

type ChoiceCardType = {
  questionId: string;
  item: ChoiceType;
  onItemUpdate: (item: ChoiceType[]) => void;
  initialDataList: ChoiceType[];
  onUploading: (toggle: boolean) => void;
};

export default function ChoiceCard({
  questionId,
  item,
  onItemUpdate,
  initialDataList,
  onUploading,
}: ChoiceCardType) {
  const { id, name, imageUrl } = item;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { params } = useRouter();
  const formId = params?.id as string;
  const { hasPermission } = useContext(AppContext) as AppContextType;
  const editAccess = hasPermission(Permissions.WRITE);
  const [uploading, setUploading] = useState(false);
  const [uploadingValue, setUploadingValue] = useState(0);

  const [getSignedUrlMutate, { loading }] = useLazyQuery(
    GET_SIGNED_URL_PICTURE_CHOICE,
    {
      onError: () => {},
    },
  );

  const handleDeleteChoice = (id: string) => {
    onItemUpdate(initialDataList.filter((option) => option.id !== id));
  };

  const handleChoiceValueChange = (id: string, obj: ChoiceResponseType) => {
    onItemUpdate(
      map(initialDataList, (option) =>
        option.id === id ? { ...option, ...obj } : option,
      ),
    );
  };

  const uploadButton = (
    <UploadSimple color="var(--content-primary)" size={24} />
  );

  const handleUpload =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async ({ file, onSuccess, onError }: any) => {
      const validateMessage = validatePageBackgroundImage(
        file,
        ALLOWED_SIZE.pictureChoiceImage,
        ALLOWED_FILE_TYPES.pictureChoiceImage,
      );

      if (validateMessage) {
        onError(new Error(validateMessage));
        return;
      }

      setUploading(true);
      getSignedUrlMutate({
        variables: {
          data: {
            optionId: id,
            questionId,
            fileName: file?.name,
            contentLength: file?.size,
            formId,
          },
        },
        onCompleted: async (res) => {
          const response = res.getPictureOptionImageUploadSignedUrl;
          const signedUrl = response?.signedUrl;
          const key = response?.key;
          const imageUrl = response?.getSignedUrl;
          if (signedUrl && key && imageUrl) {
            try {
              const success = await commonUploadFile(
                signedUrl,
                file,
                (percentage) => {
                  setUploadingValue(percentage);
                },
              );
              if (success) {
                onSuccess(response, file);
              } else {
                onError(new Error('Upload failed'));
              }
            } catch (e) {
              onError(new Error('Upload failed'));
            } finally {
              setUploading(false);
            }
          }
        },
        onError: () => {
          setUploading(false);
        },
      });
    };

  const uploadProps: UploadProps = {
    listType: 'picture-card',
    accept: ALLOWED_FILE_TYPES.pictureChoiceImage,
    multiple: false,
    showUploadList: false,
    customRequest: handleUpload,
    onChange: (info: Parameters<Required<UploadProps>['onChange']>[0]) => {
      const response = info?.file?.response;
      handleChoiceValueChange(id, {
        imageUrl: response?.getSignedUrl,
        imageKey: response?.key,
      });
    },
  };

  useEffect(() => {
    onUploading(uploading);
  }, [uploading]);

  return (
    <Flex
      gap={8}
      align="center"
      key={id}
      ref={setNodeRef}
      style={style}
      className="w-full choice-card"
      {...attributes}
    >
      {editAccess && (
        <Flex justify="center" align="center">
          <DotsSixVertical
            className="cursor-move"
            color="var(--content-tertiary)"
            size={20}
            {...listeners}
          />
        </Flex>
      )}
      <div>
        {uploading ? (
          <div className="progress-wrapper">
            <Progress
              type="line"
              percent={uploadingValue}
              strokeColor={conicColors}
              showInfo={false}
              size="small"
            />
          </div>
        ) : imageUrl ? (
          <Image
            src={imageUrl}
            width={100}
            className="object-contain"
            preview={{
              visible: false,
              mask: (
                <Upload {...uploadProps}>
                  <CommonButton
                    shape="circle"
                    icon={
                      <ArrowsClockwise
                        color="var(--surface-inverse-secondary)"
                        weight="bold"
                        size={20}
                      />
                    }
                  />
                </Upload>
              ),
            }}
            fallback={fallBackImages.pictureChoice}
          />
        ) : (
          <Upload {...uploadProps}>{uploadButton}</Upload>
        )}
      </div>
      <Input
        placeholder="Write choice label"
        value={name}
        onChange={(e) => {
          handleChoiceValueChange(id, { name: e.target.value });
        }}
      />
      {initialDataList?.length > 1 && (
        <CommonButton
          type="text"
          shadow={false}
          icon={<Trash color="var(--danger-on-surface)" size={20} />}
          shape="circle"
          onClick={() => {
            handleDeleteChoice(id);
          }}
        />
      )}
    </Flex>
  );
}
