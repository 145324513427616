/* eslint-disable no-unused-vars */
import {
  Browser,
  Buildings,
  Chat,
  ChatCenteredText,
  DownloadSimple,
  EnvelopeSimple,
  Notepad,
  Paperclip,
  Phone,
} from '@phosphor-icons/react';
import { Alert, Flex, ProgressProps, Rate, Tag } from 'antd';
import { Rule } from 'antd/es/form';
import Paragraph from 'antd/es/typography/Paragraph';
import { RcFile, UploadFile } from 'antd/es/upload';
import axios, { AxiosProgressEvent } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { FirebaseError } from 'firebase/app';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import {
  compact,
  deburr,
  isArray,
  isEmpty,
  join,
  lowerCase,
  map,
  snakeCase,
  startCase,
  trim,
} from 'lodash';
import posthog from 'posthog-js';
import { NavigateFunction } from 'react-router-dom';
import {
  FormShareDomainType,
  FormSubmissionType,
  FormThemeMode,
  WorkflowRunStatus,
} from '../__generated__/graphql';
import { messageContext } from '../components/context/AppContextHolder';
import { storage } from '../modules/auth/firebase/config';
import { FormAlert } from '../modules/form/editor/utils/FormAlert';
import {
  ChoiceType,
  CustomFile,
  EmbedConfigsType,
  EmbedTypes,
  FormFieldType,
  FormFileType,
  FormOption,
  FormSchemaType,
  FormTypeLabel,
  SocialShareType,
} from '../modules/form/form.types';
import { Feature } from '../modules/profile/profile.types';
import ImageResult from '../modules/results/utility/ImageResult';
import { MetaTypes } from '../types/common.type';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_SIZE,
  DEFAULT_DATE_FORMAT,
  REGEX,
  RESULT_DATE_FORMAT,
  TOAST_MESSAGE,
} from './constants';

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
  },
  email: () => ({
    validator(_rule: unknown, value?: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid E-mail!');
      }
      return Promise?.resolve();
    },
  }),
  name: () => ({
    validator(_rule: unknown, value?: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid name');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(_rule: unknown, value: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX.NUMBER?.test(Number(value).toString())) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Invalid phone number.');
      }
      return Promise?.resolve();
    },
  }),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const getTimeFromMins = (mins: number) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  return `${hours}h ${minutes}m`;
};

export const getBase64File = (
  img: Blob,
  // eslint-disable-next-line no-unused-vars
  callback: (result: string | ArrayBuffer | null) => void,
) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader?.addEventListener('load', () => callback(reader?.result));
  reader?.readAsDataURL(img);
};

export const formValidation: Record<string, Rule> = {
  required: {
    required: true,
    message: TOAST_MESSAGE.required,
  },
  whitespace: {
    whitespace: true,
    message: TOAST_MESSAGE.invalidInput,
  },
  email: {
    type: 'email',
    message: TOAST_MESSAGE.invalidEmail,
  },
  number: {
    pattern: REGEX.NUMBER,
    message: TOAST_MESSAGE.invalidInput,
  },
  name: {
    pattern: REGEX.NAME,
    message: TOAST_MESSAGE.invalidInput,
  },
  password: {
    pattern: REGEX.PASSWORD,
    message: TOAST_MESSAGE.invalidPassword,
  },
  fieldKey: {
    pattern: REGEX.FIELD_KEY,
    message: TOAST_MESSAGE.invalidFieldKey,
  },
  slug: {
    pattern: REGEX.SLUG,
    message: TOAST_MESSAGE.invalidSlug,
  },
  domain: {
    pattern: REGEX.DOMAIN,
    message: TOAST_MESSAGE.invalidDomain,
  },
  domainWithoutPeriod: {
    pattern: REGEX.DOMAIN_WITHOUT_PERIOD,
    message: TOAST_MESSAGE.invalidDomain,
  },
  url: {
    type: 'url',
    message: TOAST_MESSAGE.invalidUrl,
  },
};

export const getFormErrorMessage = () => ({
  required: {
    required: true,
    message: <FormAlert message={TOAST_MESSAGE.required} />,
  },
  whitespace: {
    whitespace: true,
    message: <FormAlert message={TOAST_MESSAGE.invalidInput} />,
  },
  email: {
    type: 'email',
    message: <FormAlert message={TOAST_MESSAGE.invalidEmail} />,
  },
  number: {
    pattern: REGEX.NUMBER,
    message: <FormAlert message={TOAST_MESSAGE.invalidInput} />,
  },
  name: {
    pattern: REGEX.NAME,
    message: <FormAlert message={TOAST_MESSAGE.invalidInput} />,
  },
  password: {
    pattern: REGEX.PASSWORD,
    message: <FormAlert message={TOAST_MESSAGE.invalidPassword} />,
  },
  fieldKey: {
    pattern: REGEX.FIELD_KEY,
    message: <FormAlert message={TOAST_MESSAGE.invalidFieldKey} />,
  },
});

export const handleProtectedNavigation = (
  allow: boolean,
  callback: NavigateFunction,
  path: number,
) => (allow ? callback(path) : false);

// Portal related methods
export const injectUsingPortal = (portalId: string) =>
  // eslint-disable-next-line no-undef
  document.getElementById(portalId);

export const isPortalIdExists = (portalId: string) =>
  !!injectUsingPortal(portalId);

// create cookie
export const setCookie = (
  name: string,
  value: string | number | boolean | Dayjs,
  minutes: number | null,
) => {
  let expires = '';
  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`; // using toUTCString
  }
  // eslint-disable-next-line no-undef
  document.cookie = `${name}=${value}${expires}; path=/`;
};

// retrieve cookie
export const getCookie = (cookieName: string) => {
  const name = `${cookieName}=`;
  let returnCookie = '';
  // eslint-disable-next-line no-undef
  const decodedCookie = decodeURIComponent(document?.cookie);
  const cookieArray = decodedCookie?.split(';');
  if (cookieArray?.length > 0) {
    // eslint-disable-next-line array-callback-return
    cookieArray?.map((item) => {
      let cookie = item;
      while (cookie?.charAt(0) === ' ') {
        cookie = cookie?.substring(1);
      }
      if (cookie?.indexOf(name) === 0) {
        returnCookie = cookie?.substring(name?.length, cookie?.length);
      }
    });
    return returnCookie;
  }
};

// force expired cookie or delete
export const deleteCookie = (name: string) => {
  // eslint-disable-next-line no-undef
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const formItemProps = { normalize: (value: string) => value?.trim() };

// handle firebase errors
export const handleFirebaseError = (error: unknown) => {
  if (error instanceof FirebaseError) {
    switch (error.message) {
      case 'Firebase: Error (auth/provider-already-linked).':
        return "You've already linked this account.";
      case 'Firebase: Error (auth/user-token-expired).':
        return 'Token expired. Please contact admin.';
      case 'Firebase: Error (auth/too-many-requests).':
        return 'Too many attempts. Try again later.';
      case 'Firebase: TOO_SHORT (auth/invalid-phone-number).':
        return 'Invalid phone number.';
      case 'Firebase: Error (auth/invalid-verification-code).':
        return 'Invalid verification code.';
      case 'Firebase: Error (auth/invalid-phone-number).':
        return 'Invalid phone number.';
      case 'Firebase: Error (auth/code-expired).':
        return 'Verification code expired.';
      case 'Firebase: Error (auth/popup-closed-by-user).':
        return 'Popup closed.';
      case 'Firebase: Error (auth/account-exists-with-different-credential).':
        return 'Account exists with different credential.';
      case 'Firebase: TOO_LONG (auth/invalid-phone-number).':
        return 'Invalid phone number.';
      case 'Firebase: Error (auth/error-code:-39).':
        return TOAST_MESSAGE.internalServerError;
      case 'Firebase: Error (auth/invalid-app-credential).':
        return TOAST_MESSAGE.internalServerError;
      default:
        return error.message;
    }
  } else {
    messageContext.error(TOAST_MESSAGE.somethingWentWrong);
  }
};

export const beforeUpload = (file: UploadFile) => {
  const isTypeAllowed = ALLOWED_FILE_TYPES.profileImage?.includes(
    file.type as string,
  );
  if (!isTypeAllowed) {
    messageContext?.error('You can only upload JPG/PNG file!');
    return false;
  }
  const isSizeAllowed =
    (file.size as number) / 1024 / 1024 < ALLOWED_SIZE?.profileImage;
  if (!isSizeAllowed) {
    messageContext?.error(
      `Image must be smaller than ${ALLOWED_SIZE?.profileImage}MB!`,
    );
    return false;
  }
  return isTypeAllowed && isSizeAllowed;
};

export const handleGraphQlSuccess = (message: string | undefined | null) => {
  messageContext.success(message || TOAST_MESSAGE.success);
};

export const handleGraphQlError = (message: string | undefined | null) => {
  messageContext.error(message || TOAST_MESSAGE.somethingWentWrong);
};

// function to convert string to key
export const convertToKeyFormat = (value?: string) =>
  snakeCase(value?.replace(REGEX.NON_KEY, ''));

// Function used to convert a string into unique by field
export const processObjects = (input: FormSchemaType[]): FormSchemaType[] => {
  const keyCount: { [key: string]: number } = {};

  return input.map((obj) => {
    if (!obj.answer_store_key) {
      let newKey = convertToKeyFormat(obj?.question_text);

      if (keyCount[newKey]) {
        newKey = `${newKey}_${keyCount[newKey]}`;
        keyCount[newKey]++;
      } else {
        keyCount[newKey] = 1;
      }

      // Create a new object with the same properties as the original
      const newObj = { ...obj };

      // Replace the 'key' property with the new key
      newObj.answer_store_key = newKey;

      return newObj;
    }
    return obj;
  });
};

// Function used to convert form schema into array format
export const convertFormSchemaArrayToObj = (input: FormSchemaType[]) => {
  // const allowedTypes = [
  //   FormFieldType.CONTACT_INFO,
  //   FormFieldType.ADDRESS,
  //   FormFieldType.PHONE_NUMBER,
  // ];
  return map(input, (item) => {
    // if (allowedTypes.includes(item?.field_type as FormFieldType)) {
    //   const obj: Record<string, string | boolean> = {};
    //   map(item.nested, (value) => {
    //     if (!value.hidden) {
    //       obj[value.answer_store_key] = value.is_required as boolean;
    //     }
    //   });
    //   delete item.nested;
    //   item.config = obj;
    // }
    delete item.bg_image_url;
    delete item.newItem;
    return item;
  });
};

// Function used to convert form schema into object format
export const convertFormSchemaObjToArray = (input: FormSchemaType[]) => {
  // const allowedTypes = [FormFieldType.CONTACT_INFO, FormFieldType.ADDRESS];
  return map(input, (item) => {
    item.showAllTags = false;
    // if (allowedTypes.includes(item?.field_type as FormFieldType)) {
    //   if (item.config) {
    //     const nestedFields: FormSchemaType[] = [];
    //     const formFields =
    //       item.field_type === FormFieldType.CONTACT_INFO
    //         ? STATIC_FORM_FIELDS.contactInfo
    //         : STATIC_FORM_FIELDS.address;
    //     map(formFields, (field) => {
    //       // eslint-disable-next-line no-prototype-builtins
    //       if (item?.config?.hasOwnProperty(field.answer_store_key)) {
    //         nestedFields?.push({
    //           ...field,
    //           hidden: false,
    //           is_required:
    //             item?.config?.[field.answer_store_key] === true ?? false,
    //         });
    //       } else {
    //         nestedFields?.push({
    //           ...field,
    //           hidden: true,
    //           is_required:
    //             item?.config?.[field.answer_store_key] === true ?? false,
    //         });
    //       }
    //     });
    //     item.nested = nestedFields;
    //   }
    // }
    return item;
  });
};

export const getAcceptedFileTypes = (input?: FormFileType[]) => {
  const fileTypeMapping = {
    [FormFileType.DOCUMENT]: ['.pdf', '.csv', '.docx'],
    [FormFileType.AUDIO]: ['.mp3'],
    [FormFileType.VIDEO]: ['.mp4'],
    [FormFileType.IMAGE]: ['.png', '.svg', '.jpg'],
  };

  return join(
    map(input, (type) => fileTypeMapping[type]),
    ', ',
  );
};

export const getDisabledDates = (
  current: Dayjs,
  info: {
    allowFuture?: boolean;
    allowPast?: boolean;
  },
) => {
  const today = dayjs().startOf('day');

  if (info?.allowFuture && info?.allowPast) {
    // Allow all dates
    return false;
  } else if (info?.allowFuture) {
    // Allow today & future dates
    return current && current < today;
  } else if (info?.allowPast) {
    // Allow past & future dates
    return current && current > today;
  }
  return true;
};

export const validateFileSize = (file: RcFile, maxSize: number): boolean => {
  if (file.size > maxSize * 1024 * 1024) {
    return false;
  }
  return true;
};

export const validateFileCount = (
  fileList: RcFile[],
  maxCount: number,
): boolean => {
  if (fileList.length > maxCount) {
    return false;
  }
  return true;
};

export const handleFormFileUpload = async (
  file: RcFile,
  fileList: CustomFile[],
  updateFileList: (fileList: CustomFile[]) => void,
) => {
  const storageRef = ref(storage, `uploads/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      const updatedData = fileList.map((item) =>
        item.uid === file.uid ? { ...item, percent: progress } : item,
      );
      updateFileList(updatedData);
    },
    (error) => {
      messageContext.error(error.message);
      updateFileList(
        fileList.map((item) =>
          item.uid === file.uid ? { ...item, status: 'error' } : item,
        ),
      );
    },
    async () => {
      try {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        messageContext.success(`${file.name} uploaded successfully`);
        updateFileList(
          fileList.map((item) =>
            item.uid === file.uid
              ? { ...item, status: 'done', url: downloadURL }
              : item,
          ),
        );
      } catch (error) {
        messageContext.error(`Failed to save ${file.name} metadata.`);
        updateFileList(
          fileList.map((item) =>
            item.uid === file.uid ? { ...item, status: 'error' } : item,
          ),
        );
      }
    },
  );
};

export const getInitials = (fullName: string) => {
  // Split the name into parts
  const nameParts = fullName?.split(' ');

  // Get the first letter of each part, convert to uppercase, and join
  const initials = nameParts
    ?.map((part) => part.charAt(0).toUpperCase())
    .join('');

  return initials;
};

export const inverseMode = (mode?: FormThemeMode) => {
  switch (mode) {
    case FormThemeMode.Dark:
      return FormThemeMode.Light;
    case FormThemeMode.Light:
      return FormThemeMode.Dark;
    default:
      return FormThemeMode.Light;
  }
};

export const getThemeClassName = (
  mode?: FormThemeMode | null,
  isBg = false,
  inverse = false,
) => {
  if (!inverse) {
    switch (mode) {
      case FormThemeMode.Dark:
        return isBg ? 'text-light bg-dark' : 'text-light';
      case FormThemeMode.Light:
        return isBg ? 'text-dark bg-light' : 'text-dark';
      default:
        return '';
    }
  }
  switch (mode) {
    case FormThemeMode.Dark:
      return isBg ? 'text-dark bg-light' : 'text-light';
    case FormThemeMode.Light:
      return isBg ? 'text-light bg-dark' : 'text-light';
    default:
      return '';
  }
};

export const getDefaultTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const validatePageBackgroundImage = (
  file: RcFile,
  sizeLimit: number,
  allowedTypes: string,
): string | null => {
  // commenting for future support
  // const isValidType = allowedTypes.includes(file.type);
  // if (!isValidType) {
  //   return 'You can only upload PNG, JPG, JPEG, or Webp files!';
  // }
  const isSizeAllowed = file.size / 1024 / 1024 < sizeLimit;
  if (!isSizeAllowed) {
    return `File must be smaller than ${sizeLimit}MB!`;
  }
  return null;
};

export const commonUploadFile = async (
  signedUrl: string,
  file: RcFile,
  callback?: (percent: number) => void,
) => {
  try {
    await axios.put(signedUrl, file, {
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (!callback) return;
        if (progressEvent?.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          callback(percentCompleted);
        }
      },
    });
  } catch (e) {
    return false;
  }
  return true;
};

export const getFormLink = (
  key: string,
  domainInfo: {
    domainType: FormShareDomainType;
    customLink: string;
  },
  embedConfigs?: EmbedConfigsType,
) => {
  const formBaseUrl =
    domainInfo?.domainType === FormShareDomainType.Default
      ? process.env.REACT_APP_FORM_BASE_URL
      : '';
  let url = '';
  if (domainInfo?.domainType === FormShareDomainType.Default) {
    url = `${formBaseUrl}/${key}`;
  } else {
    url = `https://${domainInfo?.customLink}`;
  }
  switch (embedConfigs?.type) {
    case EmbedTypes.IFRAME:
      return `<iframe src="${url}" height="${embedConfigs?.height?.value}${embedConfigs?.height?.unit}" width="${embedConfigs?.width?.value}${embedConfigs?.width?.unit}">`;
    case EmbedTypes.POPUP:
    case EmbedTypes.SLIDER:
    case EmbedTypes.POPOVER:
    case EmbedTypes.SIDE_TAB:
      return `<div data-zinq-live="${key}"></div><script src="${process.env.REACT_APP_EMBED_SCRIPT_URL}"></script>`;
    default:
      return url;
  }
};

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    messageContext.success(TOAST_MESSAGE.copyText);
  } catch (err) {
    messageContext.error(TOAST_MESSAGE.copyErrorText);
  }
};

export const getFormTypeOptions = (hasAlphaFeature: boolean): FormOption[] => {
  const options: FormOption[] = [
    {
      value: FormSubmissionType.Conversation,
      label: FormTypeLabel.conversational,
      icon: (
        <ChatCenteredText
          size={18}
          color="var(--content-tertiary)"
          weight="fill"
        />
      ),
    },
    // {
    //   value: FormSubmissionType.Chat,
    //   label: <Tooltip title={COMING_SOON_TEXT}>{FormTypeLabel.chat}</Tooltip>,
    //   disabled: true,
    //   icon: (
    //     <ChatsTeardrop
    //       size={18}
    //       color="var(--content-tertiary)"
    //       weight="fill"
    //     />
    //   ),
    // },
    {
      value: FormSubmissionType.Classic,
      label: FormTypeLabel.classic,
      icon: <Notepad size={18} color="var(--content-tertiary)" weight="fill" />,
    },
  ];

  // Conditionally add the "voice" option if ALPHA_FEATURE is enabled
  if (hasAlphaFeature) {
    // options.push({
    //   value: FormSubmissionType.Voice,
    //   label: FormTypeLabel.voice,
    //   icon: <Phone size={18} color="var(--content-tertiary)" weight="fill" />,
    // });

    options?.push(
      {
        value: FormSubmissionType.Voice,
        label: FormTypeLabel.voice,
        icon: <Phone size={18} color="var(--content-tertiary)" weight="fill" />,
      },
      {
        value: FormSubmissionType.Chat,
        label: FormTypeLabel.chat,
        icon: <Chat size={18} color="var(--content-tertiary)" weight="fill" />,
      },
      {
        value: FormSubmissionType.WebCall,
        label: FormTypeLabel.webCall,
        icon: (
          <Browser size={18} color="var(--content-tertiary)" weight="fill" />
        ),
      },
    );
  }

  return options;
};

export const getFileDownloadName = (formName: string) => {
  const fileName = snakeCase(deburr(formName));
  const fileNameWithDate = `${fileName}_${dayjs()?.format(DEFAULT_DATE_FORMAT)}`;
  return fileNameWithDate;
};

const format = (num: number) => num?.toString()?.padStart(2, '0');

export const formatTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    return `${format(hours)}:${format(minutes)}:${format(remainingSeconds)}`;
  } else {
    return `${format(minutes)}:${format(remainingSeconds)}`;
  }
};

export const currencyCodeToSymbol = (code: string): string => {
  const symbols: { [key: string]: string } = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    JPY: '¥',
    INR: '₹',
  };

  return symbols[code.toUpperCase()] || code;
};

export const hasFeatureAccess = (featureList: Feature[], key: string) => {
  const featureObj = featureList?.find((val: Feature) => val.key === key);
  return featureObj?.value;
};

export const disableTimes = (minTime: string, maxTime: string) => {
  if (minTime && maxTime) {
    const [minHour, minMinute] = minTime.split(':').map(Number);
    const [maxHour, maxMinute] = maxTime.split(':').map(Number);

    return {
      disabledHours: () => {
        const disabledHours = [];
        for (let hour = 0; hour < 24; hour++) {
          if (hour < minHour || hour > maxHour) {
            disabledHours.push(hour);
          }
        }
        return disabledHours;
      },
      disabledMinutes: (selectedHour: number) => {
        const disabledMinutes = [];
        for (let minute = 0; minute < 60; minute++) {
          if (selectedHour === minHour && selectedHour === maxHour) {
            // When min and max are in the same hour
            if (minute < minMinute || minute > maxMinute) {
              disabledMinutes.push(minute);
            }
          } else if (selectedHour === minHour && minute < minMinute) {
            disabledMinutes.push(minute);
          } else if (selectedHour === maxHour && minute > maxMinute) {
            disabledMinutes.push(minute);
          }
        }
        return disabledMinutes;
      },
    };
  }
  return {}; // Return an empty object if minTime or maxTime is not provided
};

export const handleSocialShare = (platform: SocialShareType, link: string) => {
  let url = '';
  switch (platform) {
    case SocialShareType.FACEBOOK:
      url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`;
      break;
    case SocialShareType.TWITTER:
      url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(link)}`;
      break;
    case SocialShareType.LINKEDIN:
      url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(link)}`;
      break;
  }
  window.open(url, '_blank');
};

export const handleDownloadQRCode = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const svg: any = document.getElementById('qr-code-svg');
  const svgData = new XMLSerializer().serializeToString(svg);
  const svgBlob = new Blob([svgData], {
    type: 'image/svg+xml;charset=utf-8',
  });
  const svgUrl = URL.createObjectURL(svgBlob);
  const downloadLink = document.createElement('a');
  downloadLink.href = svgUrl;
  downloadLink.download = 'qrcode.svg';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const triggerEvent = (eventName: string, metaInfo: MetaTypes) => {
  const environment = process.env.REACT_APP_ENV;
  if (environment !== 'localhost') {
    posthog.capture(eventName, metaInfo);
  }
};

export const validateEmails = (emails: string[]): boolean => {
  return emails.every((email) => REGEX.EMAIL.test(email));
};

export const formatTimeDifference = (value: string) => {
  const diffInMinutes = dayjs().diff(dayjs(value), 'minute');
  const diffInHours = dayjs().diff(dayjs(value), 'hour');
  const diffInDays = dayjs().diff(dayjs(value), 'day');

  if (diffInDays > 0) {
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
  } else {
    return `${diffInMinutes} min ago`;
  }
};

export const renderWebhookStatus = (status: WorkflowRunStatus) => {
  const message = startCase(lowerCase(status));
  const commonClassName = 'small';
  switch (status) {
    case WorkflowRunStatus.Complete:
      return (
        <Alert message={message} type="success" className={commonClassName} />
      );
    case WorkflowRunStatus.Errored:
    case WorkflowRunStatus.Terminated:
      return (
        <Alert message={message} type="error" className={commonClassName} />
      );
    case WorkflowRunStatus.Queued:
    case WorkflowRunStatus.WaitingForPause:
    case WorkflowRunStatus.Waiting:
    case WorkflowRunStatus.Running:
      return (
        <Alert message={message} type="warning" className={commonClassName} />
      );
    default:
      return (
        <Alert message={message} type="info" className={commonClassName} />
      );
  }
};
// function to convert string into title case
export const toTitleCase = (text: string | undefined | null) => {
  if (!text) return '';
  return startCase(lowerCase(trim(text)));
};

export const conicColors: ProgressProps['strokeColor'] = {
  '0%': 'var(--link)',
  '50%': 'var(-link)',
  '100%': 'var(--success-on-surface)',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getColumnBody = (value: any, formSchema: FormSchemaType) => {
  switch (formSchema?.field_type) {
    case FormFieldType.DATE:
      return dayjs(value)?.format(RESULT_DATE_FORMAT);
    case FormFieldType.SHORT_TEXT:
    case FormFieldType.LONG_TEXT:
    case FormFieldType.EMAIL:
    case FormFieldType.NUMBER:
    case FormFieldType.PHONE_NUMBER:
    case FormFieldType.TIME:
      return value;
    case FormFieldType.ADDRESS: {
      const addressParts = [
        value?.address_line_1,
        value?.address_line_2,
        value?.city_town,
        value?.state_region_province,
        value?.zip_post_code,
        value?.country,
      ];
      return compact(addressParts)?.join(', ') || '-';
    }
    case FormFieldType.CONTACT_INFO: {
      const fullName = `${value?.first_name || ''} ${value?.last_name || ''}`;
      return (
        <div className="flex flex-column gap-6">
          <div className="text-sm">{trim(fullName) || '-'}</div>
          <div className="text-sm flex items-center gap-6 text-content-secondary">
            <Phone size={14} color="var(--content-primary)" weight="fill" />
            {value?.phone || '-'}
          </div>
          <div className="text-sm flex items-center gap-6 text-content-secondary">
            <EnvelopeSimple
              size={14}
              color="var(--content-primary)"
              weight="fill"
            />
            {value?.email || '-'}
          </div>
          <div className="text-sm flex items-center gap-6 text-content-secondary">
            <Buildings size={14} color="var(--content-primary)" weight="fill" />
            {value?.company_name || '-'}
          </div>
        </div>
      );
    }
    case FormFieldType.FILE:
      return (
        <Flex gap={8} wrap>
          {!isEmpty(value)
            ? isArray(value) &&
              map(value, (file, idx) => {
                return (
                  <a
                    href={file?.url}
                    target="_blank"
                    rel="noreferrer"
                    key={idx}
                  >
                    <Tag
                      bordered={false}
                      className="download-btn text-content-primary"
                    >
                      <Paperclip size={14} color="var(--content-primary)" />
                      {file?.fileName}
                      <DownloadSimple
                        size={14}
                        color="var(--content-primary)"
                      />
                    </Tag>
                  </a>
                );
              })
            : '-'}
        </Flex>
      );
    case FormFieldType.SELECT:
    case FormFieldType.MULTI_SELECT:
      return isArray(value) ? value?.join(', ') : value;
    case FormFieldType.LINEAR_SCALE:
      return (
        <Flex className="mb-0" align="flex-end">
          <Paragraph className="mb-0 text-content-primary text-base semi-bold">
            {value}
          </Paragraph>
          <Paragraph className="mb-0 text-content-secondary">{`/${formSchema.config?.max_rating}`}</Paragraph>
        </Flex>
      );
    case FormFieldType.RATE:
      return (
        <Rate
          disabled
          defaultValue={Number(value)}
          count={formSchema.config?.max_rating}
        />
      );
    case FormFieldType.PICTURE_CHOICE:
      return (
        <Flex gap={8} wrap>
          {!isEmpty(value)
            ? isArray(value) &&
              map(value, (option: ChoiceType, idx: number) => {
                const obj = (formSchema?.options as ChoiceType[])?.find(
                  (item) => item?.id === option?.id,
                );

                if (!isEmpty(obj)) {
                  return (
                    <Tag
                      bordered={false}
                      className="download-btn text-content-primary"
                    >
                      <ImageResult src={obj?.imageUrl} alt={obj?.name} />
                      {obj?.name}
                    </Tag>
                  );
                }
                return (
                  <Tag
                    bordered={false}
                    className="download-btn text-content-primary"
                  >
                    <ImageResult src={option?.imageUrl} alt={option?.name} />
                    {option?.name}
                  </Tag>
                );
              })
            : '-'}
        </Flex>
      );
    default:
      return value;
  }
};
