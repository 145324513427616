import { DeleteOutlined } from '@ant-design/icons';
import {
  CaretDown,
  CaretLeft,
  CaretUp,
  CaretUpDown,
  Eye,
  EyeSlash,
  PencilSimple,
  Trash,
} from '@phosphor-icons/react';
import {
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  TimePicker,
  Tooltip,
  Typography,
} from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import dayjs from 'dayjs';
import { isArray, map } from 'lodash';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../../AppContext';
import {
  DROPDOWN_LIST,
  featureDefaultValues,
  FORM_CONTROLS,
  formConfig,
  ROUTES,
} from '../../../../common/constants';
import { hasFeatureAccess } from '../../../../common/utils';
import CommonKeyModal from '../../../../components/common/CommonKeyModal';
import SubscribeModal from '../../../../components/common/SubscribeModal';
import CommonButton from '../../../../components/primitives/CommonButton';
import CommonCheckBox from '../../../../components/primitives/CommonCheckBox';
import CommonSwitch from '../../../../components/primitives/CommonSwitch';
import CommonTag from '../../../../components/primitives/CommonTag';
import useFeatureAccess from '../../../../hooks/useFeatureAccess';
import { AppContextType } from '../../../../types/appContext.type';
import {
  FeatureName,
  FormControlType,
  ID,
  Permissions,
} from '../../../../types/common.type';
import ComingSoonText from '../../../branding/ComingSoonText';
import {
  ChoiceType,
  FormFileType,
  FormSchemaType,
  InputChangeTypes,
  NestedInputChangeTypes,
} from '../../../form/form.types';
import { FeatureKeys } from '../../../profile/profile.types';
import { FormFieldType } from '../../form.types';
import OptionList from '../OptionList';
import ChoiceBuilder from '../fields/picture-choice/ChoiceBuilder';
import CommonCheckLayout from '../layouts/CommonCheckLayout';
import FormFieldsPagination from '../utils/FormFieldsPagination';
import InfoLabel from '../utils/InfoLabel';
import QuickAddQuestion from '../utils/QuickAddQuestion';
import { CommonPageTypes } from './pages.types';

export const EditFormField: React.FC<
  CommonPageTypes & {
    item: FormSchemaType;
    itemList: FormSchemaType[];
    paginationProps: {
      total: number;
      onChange: (id: FormSchemaType) => void;
    };
    onInputChange: ({ id, path, value }: InputChangeTypes) => void;
    onNestedFieldChange: ({
      key,
      field,
      value,
      nestedId,
    }: NestedInputChangeTypes) => void;
    onDelete: (value: ID) => void;
    onFieldTypeChange: (key: number | string, type: FormFieldType) => void;
    optionsMapping: Record<string, React.ReactNode>;
    onManualQuestionAdd: (id?: string) => void;
  }
> = ({
  onBack,
  item,
  itemList,
  onInputChange,
  onNestedFieldChange,
  onDelete,
  optionsMapping,
  onFieldTypeChange,
  paginationProps,
  onManualQuestionAdd,
}) => {
  const {
    state: { currentUser },
    getWorkspaceId,
    hasPermission,
  } = useContext(AppContext) as AppContextType;

  const editAccess = hasPermission(Permissions.WRITE);
  const [uploading, setUploading] = useState(false);

  const [isEditKeyModalOpen, setIsEditKeyModalOpen] = useState(false);

  const questionNumber =
    itemList.findIndex((val) => val.question_id === item.question_id) + 1;

  const allowedFileSize = hasFeatureAccess(
    currentUser?.subscriptionPlan?.features,
    FeatureKeys.FORM_FILE_UPLOAD_SIZE,
  );

  const {
    checkFeatureAccess,
    isSubscriptionModalVisible,
    handleUpgradeSuccess,
    closeSubscriptionModal,
  } = useFeatureAccess();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: 'var(--surface-secondary)',
          colorPrimary: '#a9aeb1',
        },
        components: {
          Form: {
            labelColor: 'var(--content-secondary)',
            labelFontSize: 14,
            verticalLabelPadding: 0,
            itemMarginBottom: 0,
            fontWeightStrong: 500,
          },
          Input: {
            borderRadius: 16,
            colorBorder: 'var(--border-primary)',
            colorText: 'var(--content-primary)',
          },
          InputNumber: {
            borderRadius: 16,
            colorText: 'var(--content-primary)',
            colorBorder: 'var(--border-primary)',
          },
          Select: {
            borderRadius: 16,
            colorText: 'var(--content-primary)',
            colorBorder: 'var(--border-primary)',
            optionActiveBg: 'var(--surface-tertiary)',
            optionSelectedBg: 'var(--surface-tertiary)',
          },
          DatePicker: {
            borderRadius: 16,
            colorBorder: 'var(--border-primary)',
          },
        },
      }}
    >
      <Form disabled={!editAccess || uploading}>
        <section className="form-selected-item">
          <Flex
            gap={8}
            wrap
            align="center"
            justify="space-between"
            className="scroll-wrapper-header pb-12 mb-16"
          >
            <CommonButton
              type="text"
              onClick={() => onBack()}
              shape="circle"
              shadow={false}
              disabled={false}
              icon={<CaretLeft color="var(--content-primary)" size={20} />}
            />
            <Flex wrap gap={24} align="center">
              <Flex gap={12} align="center">
                {item.field_type !== FormFieldType.ADDRESS &&
                item.field_type !== FormFieldType.CONTACT_INFO ? (
                  <>
                    <Paragraph className="mb-0 text-content-primary text-base semi-bold">
                      Required
                    </Paragraph>
                    <CommonSwitch
                      type="primary"
                      checked={item.is_required}
                      onChange={(checked) =>
                        onInputChange({
                          id: item.question_id as string,
                          path: ['is_required'],
                          value: [checked],
                        })
                      }
                    />
                  </>
                ) : null}
              </Flex>
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure, you want to delete?"
                  okText="Yes"
                  placement="right"
                  className="hover-show"
                  onConfirm={() => onDelete(item.question_id as string)}
                  okButtonProps={{
                    block: true,
                    icon: <DeleteOutlined />,
                    danger: true,
                  }}
                  cancelButtonProps={{ block: true }}
                  icon={null}
                >
                  <CommonButton
                    type="text"
                    shape="circle"
                    shadow={false}
                    icon={<Trash color="var(--danger-on-surface)" size={20} />}
                  />
                </Popconfirm>
              </Tooltip>
            </Flex>
          </Flex>
          <div className="scroll-wrapper-body">
            <Row gutter={[16, 24]}>
              <Col lg={24}>
                <Form.Item
                  label={
                    <Flex gap={4} align="center" className="mb-8">
                      <Paragraph className="mb-0 text-content-tertiary bg-surface-secondary p-4 text-base semi-bold border-sm">
                        {questionNumber}
                      </Paragraph>
                      <label>Question</label>
                    </Flex>
                  }
                  labelCol={{ span: 24 }}
                >
                  <Input.TextArea
                    value={item?.question_text}
                    placeholder="Type here..."
                    autoSize={{ minRows: 3 }}
                    autoFocus
                    onChange={(e) => {
                      const value = e.target.value;
                      onInputChange({
                        id: item.question_id || '',
                        path: ['question_text'],
                        value: [value],
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              {/* commented for future use */}
              {/* <Col span={24} lg={24} xl={16}>
                <Form.Item
                  label="Title"
                  labelCol={{ span: 24 }}
                  rules={[formValidation.required]}
                  required
                >
                  <Input
                    value={item?.question_title}
                    placeholder="Type here..."
                    required
                    onChange={(e) => {
                      const value = e.target.value;
                      onInputChange({
                        id: item.question_id || '',
                        path: ['question_title'],
                        value: [value],
                      });
                    }}
                  />
                </Form.Item>
              </Col> */}
              <Col span={24}>
                <Form.Item label="Field Type" labelCol={{ span: 24 }}>
                  <Select
                    showSearch
                    className="w-full"
                    placeholder="Select"
                    optionFilterProp="label"
                    suffixIcon={
                      <CaretUpDown
                        size={18}
                        weight="fill"
                        color="var(--content-secondary)"
                      />
                    }
                    value={item.field_type}
                    onChange={(
                      value,
                      option: FormControlType | FormControlType[],
                    ) => {
                      if (!isArray(option)) {
                        const { isPremium, isComingSoon } = option;
                        if (isComingSoon) {
                          return true;
                        } else if (isPremium) {
                          checkFeatureAccess(
                            FeatureKeys.ADVANCED_FIELDS,
                            () => {
                              onFieldTypeChange(
                                item.question_id as string,
                                value as FormFieldType,
                              );
                            },
                          );
                        } else {
                          onFieldTypeChange(
                            item.question_id as string,
                            value as FormFieldType,
                          );
                        }
                      }
                    }}
                    labelRender={(data) => (
                      <Flex align="center" gap={8}>
                        <span className="flex">
                          {optionsMapping?.[data?.value]}
                        </span>
                        <Paragraph className="mb-0">{data?.label}</Paragraph>
                      </Flex>
                    )}
                    options={FORM_CONTROLS}
                    optionRender={(option) => (
                      <Flex align="center" justify="space-between" gap={8} wrap>
                        <Flex align="center" gap={8}>
                          <Flex>{optionsMapping?.[option.data.value]}</Flex>
                          <Typography.Text className="text-base text-content-primary">
                            {option.data.label}
                          </Typography.Text>
                          {option.data.isComingSoon && <ComingSoonText />}
                        </Flex>
                      </Flex>
                    )}
                  />
                </Form.Item>
              </Col>
              {item.field_type === FormFieldType.PICTURE_CHOICE && (
                <Col md={24}>
                  <Form.Item labelCol={{ span: 24 }}>
                    <Flex gap={16} vertical>
                      {/* <Paragraph></Paragraph> */}
                      <CommonCheckLayout
                        title="Allow multiple selection"
                        description=""
                        checked={item?.config?.allow_multiple as boolean}
                        onChange={(value) =>
                          onInputChange({
                            id: item.question_id || '',
                            path: ['config.allow_multiple'],
                            value: [value],
                          })
                        }
                        featureName={FeatureName.FutureDate}
                      />
                    </Flex>
                  </Form.Item>
                </Col>
              )}
              {item.field_type !== FormFieldType.EMAIL &&
                item.field_type !== FormFieldType.PHONE_NUMBER && (
                  <Col lg={24}>
                    <Divider dashed className="selected-item-divider"></Divider>
                  </Col>
                )}
              {(item.field_type === FormFieldType.LONG_TEXT ||
                item.field_type === FormFieldType.SHORT_TEXT) && (
                <Col lg={12}>
                  <Form.Item label="Character Limit" labelCol={{ span: 24 }}>
                    <InputNumber
                      className="w-full"
                      value={item?.config?.character_limit}
                      placeholder="0 - 999999999"
                      min={0}
                      onChange={(value) => {
                        if (Number(value) >= 0 && Number(value) <= 999999999) {
                          onInputChange({
                            id: item.question_id || '',
                            path: ['config.character_limit'],
                            value: [value as number],
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
              {item.field_type === FormFieldType.NUMBER && (
                <>
                  <Col lg={12}>
                    <Form.Item label="Min. Number" labelCol={{ span: 24 }}>
                      <InputNumber
                        className="w-full"
                        value={item?.config?.min_number}
                        placeholder="Enter minimum value"
                        min={formConfig.defaultMinNumber}
                        max={item?.config?.max_number}
                        onChange={(value) => {
                          onInputChange({
                            id: item.question_id || '',
                            path: ['config.min_number'],
                            value: [value as number],
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item label="Max. Number" labelCol={{ span: 24 }}>
                      <InputNumber
                        className="w-full"
                        value={item?.config?.max_number}
                        placeholder="Enter maximum value"
                        min={item?.config?.min_number}
                        max={formConfig.defaultMaxNumber}
                        onChange={(value) => {
                          onInputChange({
                            id: item.question_id || '',
                            path: ['config.max_number'],
                            value: [value as number],
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              {item.field_type === FormFieldType.RATE && (
                <Col lg={12}>
                  <Form.Item label="Max. Rating" labelCol={{ span: 24 }}>
                    <Select
                      options={DROPDOWN_LIST.ratingList}
                      value={item.config?.max_rating}
                      onChange={(value) => {
                        onInputChange({
                          id: item.question_id || '',
                          path: ['config.max_rating'],
                          value: [value as number],
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              )}

              {item.field_type === FormFieldType.PICTURE_CHOICE &&
                item?.question_id && (
                  <ChoiceBuilder
                    questionId={item.question_id}
                    defaultChoiceList={item?.options as ChoiceType[]}
                    onUploading={(toggle: boolean) => {
                      setUploading(toggle);
                    }}
                    onChange={(
                      path: string[],
                      value:
                        | Array<string | number | boolean | ChoiceType>
                        | Array<Array<string | number | boolean | ChoiceType>>,
                    ) =>
                      onInputChange({ id: item.question_id || '', path, value })
                    }
                  />
                )}
              {item.field_type === FormFieldType.LINEAR_SCALE && (
                <>
                  <Col lg={12}>
                    <Form.Item label="From" labelCol={{ span: 24 }}>
                      <Select
                        options={DROPDOWN_LIST.minRatingList}
                        value={item?.config?.min_rating}
                        suffixIcon={
                          <CaretDown
                            color="var(--content-tertiary)"
                            size={18}
                          />
                        }
                        onChange={(value) => {
                          onInputChange({
                            id: item.question_id || '',
                            path: ['config.min_rating'],
                            value: [value as number],
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item label="To" labelCol={{ span: 24 }}>
                      <Select
                        options={DROPDOWN_LIST.maxRatingList}
                        value={item.config?.max_rating}
                        suffixIcon={
                          <CaretDown
                            color="var(--content-tertiary)"
                            size={18}
                          />
                        }
                        onChange={(value) => {
                          onInputChange({
                            id: item.question_id || '',
                            path: ['config.max_rating'],
                            value: [value as number],
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item
                      label={`Label ${item?.config?.min_rating}`}
                      labelCol={{ span: 24 }}
                    >
                      <Input
                        className="w-full"
                        value={item?.config?.min_label}
                        placeholder={`Enter Label ${item?.config?.min_rating}`}
                        onChange={(e) => {
                          onInputChange({
                            id: item.question_id || '',
                            path: ['config.min_label'],
                            value: [e.target.value],
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item
                      label={`Label ${item?.config?.max_rating}`}
                      labelCol={{ span: 24 }}
                    >
                      <Input
                        className="w-full"
                        value={item?.config?.max_label}
                        placeholder={`Enter Label ${item?.config?.max_rating}`}
                        onChange={(e) => {
                          onInputChange({
                            id: item.question_id || '',
                            path: ['config.max_label'],
                            value: [e.target.value],
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}

              {item.field_type === FormFieldType.DATE && (
                <Col md={24}>
                  <Form.Item labelCol={{ span: 24 }}>
                    <Flex gap={16} vertical>
                      <CommonCheckLayout
                        title="Allow past dates"
                        description="Users will be able to select past dates"
                        checked={item?.config?.allow_past_date as boolean}
                        onChange={(value) =>
                          onInputChange({
                            id: item.question_id || '',
                            path: ['config.allow_past_date'],
                            value: [value],
                          })
                        }
                        featureName={FeatureName.PastDate}
                      />
                      <CommonCheckLayout
                        title="Allow future dates"
                        description="Users will be able to select future dates"
                        checked={item?.config?.allow_future_date as boolean}
                        onChange={(value) =>
                          onInputChange({
                            id: item.question_id || '',
                            path: ['config.allow_future_date'],
                            value: [value],
                          })
                        }
                        featureName={FeatureName.FutureDate}
                      />
                    </Flex>
                  </Form.Item>
                </Col>
              )}
              {item.field_type === FormFieldType.FILE && (
                <>
                  <Col xl={12}>
                    <Form.Item
                      label="Max. No. Of Files"
                      labelCol={{ span: 24 }}
                    >
                      <InputNumber
                        className="w-full"
                        min={1}
                        value={item.config?.max_file_count}
                        onChange={(value) => {
                          if (value) {
                            onInputChange({
                              id: item.question_id || '',
                              path: ['config.max_file_count'],
                              value: [value],
                            });
                          }
                        }}
                        controls={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12}>
                    <Form.Item
                      label={`Max. File Size (Upto ${allowedFileSize} MB)`}
                      labelCol={{ span: 24 }}
                    >
                      <InputNumber
                        className="w-full"
                        // addonBefore="-"
                        // addonAfter="+"
                        value={item.config?.max_file_size}
                        onChange={(value) => {
                          if (value) {
                            onInputChange({
                              id: item.question_id || '',
                              path: ['config.max_file_size'],
                              value: [value],
                            });
                          }
                        }}
                        controls={false}
                        min={0.5}
                        max={allowedFileSize}
                      />
                      {allowedFileSize !==
                        featureDefaultValues.FORM_FILE_UPLOAD_SIZE && (
                        <Paragraph className="mb-0 text-content-secondary">
                          {`Get ${featureDefaultValues.FORM_FILE_UPLOAD_SIZE}MB uploads with `}
                          <Link
                            to={`${ROUTES.PLAN_BILLING}/${getWorkspaceId()}`}
                          >
                            <span className="text-underline">Premium</span>
                          </Link>
                        </Paragraph>
                      )}
                    </Form.Item>
                  </Col>
                  <Col lg={24}>
                    <Checkbox.Group
                      defaultValue={item?.config?.file_type as FormFileType[]}
                      className="file-type-checkbox-group"
                      onChange={(value) => {
                        onInputChange({
                          id: item.question_id || '',
                          path: ['config.file_type'],
                          value: [value],
                        });
                      }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Paragraph className="mb-0 text-content-secondary">
                            Supported Formats:
                          </Paragraph>
                        </Col>
                        {map(DROPDOWN_LIST.fileCheckBoxList, (item, idx) => {
                          return (
                            <Col span={24} key={idx}>
                              <CommonCheckBox
                                value={item.value}
                                boxType="primary"
                              >
                                {item.label}
                              </CommonCheckBox>
                            </Col>
                          );
                        })}
                      </Row>
                    </Checkbox.Group>
                  </Col>
                </>
              )}
              {item.field_type === FormFieldType.TIME && (
                <>
                  <Col lg={12}>
                    <Form.Item label="Time Range" labelCol={{ span: 24 }}>
                      <TimePicker.RangePicker
                        format="HH:mm"
                        value={
                          item?.config?.min_time && item?.config?.min_time
                            ? [
                                dayjs(item?.config?.min_time, 'HH:mm'),
                                dayjs(item?.config?.max_time, 'HH:mm'),
                              ]
                            : null
                        }
                        onChange={(value, timeString) => {
                          if (timeString) {
                            onInputChange({
                              id: item.question_id || '',
                              path: ['config.min_time', 'config.max_time'],
                              value: [timeString?.[0], timeString?.[1]],
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              {(item.field_type === FormFieldType.CONTACT_INFO ||
                item.field_type === FormFieldType.ADDRESS) &&
                item?.nested &&
                item?.nested?.length > 0 && (
                  <Col span={24}>
                    <Flex vertical gap={16} className="input-nested-fields">
                      {map(item?.nested, (value: FormSchemaType) => {
                        return (
                          <Flex
                            gap={12}
                            className="row-wrapper w-full"
                            align="center"
                          >
                            <Tooltip
                              title={
                                value?.hidden ? 'Show field' : 'Hide field'
                              }
                            >
                              <CommonButton
                                type="text"
                                shadow={false}
                                icon={
                                  !value?.hidden ? (
                                    <Eye
                                      color="var(--content-primary)"
                                      weight="fill"
                                      size={20}
                                    />
                                  ) : (
                                    <EyeSlash
                                      color="var(--content-primary)"
                                      size={20}
                                      weight="fill"
                                    />
                                  )
                                }
                                shape="circle"
                                onClick={() => {
                                  onNestedFieldChange({
                                    key: item.question_id || '',
                                    field: 'hidden',
                                    value: value.hidden ? false : true,
                                    nestedId: value.answer_store_key,
                                  });
                                }}
                              />
                            </Tooltip>
                            <CommonTag
                              color="warning"
                              className={`w-full ${value?.hidden ? 'opacity-4' : ''}`}
                            >
                              {value?.name}
                            </CommonTag>
                            <Flex align="center" gap={8}>
                              <Paragraph
                                className={`word-no-break mb-0 text-content-primary medium ${value?.hidden ? 'opacity-4' : ''}`}
                              >
                                Required
                              </Paragraph>
                              <CommonSwitch
                                type="primary"
                                checked={value.is_required}
                                disabled={value?.hidden || !editAccess}
                                onChange={(checked) =>
                                  onNestedFieldChange({
                                    key: item.question_id || '',
                                    field: 'is_required',
                                    value: checked,
                                    nestedId: value.answer_store_key,
                                  })
                                }
                              />
                            </Flex>
                          </Flex>
                        );
                      })}
                    </Flex>
                  </Col>
                )}
              {(item.field_type === FormFieldType.MULTI_SELECT ||
                item.field_type === FormFieldType.SELECT) && (
                <Col lg={24}>
                  <Collapse
                    ghost
                    className="options-list-collapse"
                    expandIconPosition="right"
                    defaultActiveKey="1"
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <CaretUp size={16} color="var(--content-tertiary)" />
                      ) : (
                        <CaretDown size={16} color="var(--content-tertiary)" />
                      )
                    }
                  >
                    <Collapse.Panel
                      header={
                        <Paragraph className="mb-0 text-content-primary text-base semi-bold">
                          Options
                        </Paragraph>
                      }
                      key="1"
                    >
                      <OptionList
                        value={(item?.options as string[]) || []}
                        id={item.question_id || ''}
                        onChange={(
                          id: ID,
                          path: string[],
                          value: Array<Array<string>>,
                        ) => onInputChange({ id, path, value })}
                      />
                    </Collapse.Panel>
                  </Collapse>
                </Col>
              )}

              {/* common for every form control type */}
              <Col lg={24}>
                <Divider dashed className="selected-item-divider"></Divider>
              </Col>
              <Col lg={24}>
                <Row gutter={[16, 16]}>
                  <Col lg={24}>
                    <Paragraph className="mb-0 text-base semi-bold">
                      Advance Settings
                    </Paragraph>
                  </Col>
                  {/* commented for future use */}
                  {/* <Col lg={24}>
                    <Form.Item
                      label="Guidelines for AI"
                      labelCol={{
                        span: 24,
                      }}
                    >
                      <Input
                        value={item.ai_guideline}
                        placeholder="Provide helpful tips or details for AI to assist users during form filling..."
                        onChange={(e) =>
                          onInputChange({
                            id: item.question_id || '',
                            path: ['ai_guideline'],
                            value: [e.target.value],
                          })
                        }
                      />
                    </Form.Item>
                  </Col> */}
                  <Col lg={24}>
                    <Form.Item
                      label={
                        <InfoLabel
                          title="Field name for export"
                          tooltipText="Key which will be used in CSV export"
                        />
                      }
                      labelCol={{
                        span: 24,
                      }}
                    >
                      <Input
                        value={item.answer_store_key}
                        disabled
                        placeholder="Enter a unique identifier for export data..."
                        onChange={(e) => {
                          onInputChange({
                            id: item.question_id || '',
                            path: ['answer_store_key', 'is_user_key'],
                            value: [e.target.value, true],
                          });
                        }}
                        // onBlur={(e) => {
                        //   handleOnInputBlur(e.target.value);
                        // }}
                        suffix={
                          editAccess && (
                            <PencilSimple
                              size={18}
                              color="var(--content-primary)"
                              className="cursor-pointer"
                              weight="fill"
                              onClick={() => {
                                setIsEditKeyModalOpen(true);
                              }}
                            />
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {itemList?.length > 0 && (
            <div className="form-field-pagination">
              <Flex className="field-wrapper">
                <FormFieldsPagination
                  itemList={itemList}
                  currentItem={item as FormSchemaType}
                  total={paginationProps.total}
                  onChange={(item) => paginationProps.onChange(item)}
                />
                <QuickAddQuestion
                  onClick={() => onManualQuestionAdd(item?.question_id)}
                />
              </Flex>
            </div>
          )}
        </section>
      </Form>
      {isEditKeyModalOpen && (
        <CommonKeyModal
          isVisible={isEditKeyModalOpen}
          onCancel={() => setIsEditKeyModalOpen(false)}
          onConfirm={(value) => {
            onInputChange({
              id: item.question_id || '',
              path: ['answer_store_key'],
              value: [value],
            });
            setIsEditKeyModalOpen(false);
          }}
          title="Field name for export"
          description="This will be used for results export."
          initialValue={item?.answer_store_key as string}
        />
      )}
      {isSubscriptionModalVisible && (
        <SubscribeModal
          visible={isSubscriptionModalVisible}
          onCancel={closeSubscriptionModal}
          onUpgrade={handleUpgradeSuccess}
          featureName={FeatureName.AdvancedField}
        />
      )}
    </ConfigProvider>
  );
};
