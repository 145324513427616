import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { ConfigProvider, Pagination } from 'antd';
import { FormSchemaType } from '../../form.types';

type FormFieldsPaginationProps = {
  itemList: FormSchemaType[];
  currentItem: FormSchemaType;
  total: number;
  onChange: (item: FormSchemaType) => void;
};

const FormFieldsPagination: React.FC<FormFieldsPaginationProps> = ({
  itemList,
  currentItem,
  total,
  onChange,
}) => {
  const currentIndex = itemList.findIndex(
    (val) => val.question_id === currentItem?.question_id,
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 16,
          colorLink: '#A9AEB1',
          colorLinkActive: '#A9AEB1',
        },
        components: {
          Pagination: {
            colorPrimary: 'var(--content-primary)',
            controlHeight: 32,
          },
        },
      }}
    >
      <Pagination
        className="form-fields-pagination"
        current={currentIndex + 1}
        total={total}
        pageSize={1}
        showLessItems
        onChange={(page) => {
          const selectedItem = itemList[page - 1];
          if (selectedItem) {
            onChange(selectedItem);
          }
        }}
        showSizeChanger={false}
        prevIcon={<CaretLeft size={20} color="var(--content-tertiary)" />}
        nextIcon={<CaretRight size={20} color="var(--content-tertiary)" />}
      />
    </ConfigProvider>
  );
};

export default FormFieldsPagination;
