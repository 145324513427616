import { Flex, Image } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import cn from 'classnames';
import { trim } from 'lodash';
import styled from 'styled-components';
import { fallBackImages } from '../../../../../common/constants';
import { ChoiceType, Palette } from '../../../form.types';

const OptionWrapper = styled.span`
  .picture-choice-option {
    padding: 4px;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.3s ease;
    border: 1px solid ${(props) => `${props.theme?.textColor}80`};
    .zinq-image {
      img {
        border-radius: 4px;
      }
    }
    &:hover,
    &.active {
      box-shadow:
        0px 4px 8px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.1),
        0px 0px 0px 2px #fff,
        0px 0px 0px 3px ${(props) => props.theme?.textColor};
    }
  }
`;

export default function ChoiceOption({
  item,
  onClick,
  activeTheme,
  className,
}: {
  item: ChoiceType;
  activeTheme: Palette;
  onClick: () => void;
  className: string;
}) {
  return (
    <OptionWrapper theme={activeTheme}>
      <div className={cn('picture-choice-option', className)} onClick={onClick}>
        <Flex vertical gap={8} align="flex-start">
          <Image
            src={item?.imageUrl || fallBackImages.pictureChoice}
            alt={item?.name}
            preview={false}
            className="object-contain preview-choice-img"
            fallback={fallBackImages.pictureChoice}
          />

          {trim(item?.name) && (
            <Paragraph
              className="mb-0 choice-text"
              style={{
                color: activeTheme?.textColor,
              }}
            >
              {item.name}
            </Paragraph>
          )}
        </Flex>
      </div>
    </OptionWrapper>
  );
}
