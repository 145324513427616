import { Plus } from '@phosphor-icons/react';
import { Tooltip } from 'antd';
import CommonButton from '../../../../components/primitives/CommonButton';

type QuickAddQuestionType = {
  onClick: () => void;
};
export default function QuickAddQuestion({ onClick }: QuickAddQuestionType) {
  return (
    <div onClick={onClick}>
      <Tooltip title="Add question">
        <CommonButton
          shape="circle"
          icon={<Plus size={18} color="var(--content-primary)" />}
        />
      </Tooltip>
    </div>
  );
}
