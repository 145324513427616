import { ConfigProvider, Flex, Progress } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { map } from 'lodash';
import { SubmissionByQuestionData } from '../../../__generated__/graphql';
import { ChoiceType } from '../../form/form.types';
import ImageResult from './ImageResult';

export default function ChoiceGraph({
  chartData,
  optionList,
}: {
  chartData: SubmissionByQuestionData[];
  optionList: ChoiceType[];
}) {
  const total = chartData?.reduce((sum, num) => {
    return sum + (num?.count || 0);
  }, 0);
  return (
    <ConfigProvider
      theme={{
        components: {
          Progress: {
            defaultColor: '#6542DC',
            lineBorderRadius: 4,
          },
        },
      }}
    >
      <Flex vertical gap={24} className="choice-graph-wrapper">
        {map(optionList, (option) => {
          const optionMeta = chartData?.find((i) => i.value === option?.id);
          const percent =
            total === 0
              ? 0
              : Number((((optionMeta?.count || 0) / total) * 100).toFixed(0));
          return (
            <Flex gap={16} align="center">
              <ImageResult
                width={64}
                src={option?.imageUrl}
                alt={option?.name}
              />
              <Flex vertical className="w-full" gap={4}>
                <Flex gap={8} justify="space-between" align="center">
                  {option?.name && (
                    <Paragraph className="mb-0 text-content-secondary medium">
                      {option?.name}
                    </Paragraph>
                  )}
                  <Flex align="center" gap={16}>
                    <Paragraph className="mb-0 text-content-tertiary text-sm word-no-break">
                      {`${optionMeta?.count || 0} resp.`}
                    </Paragraph>
                    <Paragraph className="mb-0 text-content-secondary word-no-break">
                      {`${percent}%`}
                    </Paragraph>
                  </Flex>
                </Flex>
                <Progress
                  // strokeLinecap="butt"
                  type="line"
                  percent={percent}
                  showInfo={false}
                  size={{
                    height: 24,
                  }}
                />
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </ConfigProvider>
  );
}
