import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Plus } from '@phosphor-icons/react';
import { Flex } from 'antd';
import { map } from 'lodash';
import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';
import CommonButton from '../../../../../components/primitives/CommonButton';
import { ChoiceType } from '../../../form.types';
import ChoiceCard from './ChoiceCard';

export default function ChoiceBuilder({
  questionId,
  onChange,
  defaultChoiceList,
  onUploading,
}: {
  questionId: string;
  onUploading: (toggle: boolean) => void;
  onChange: (
    key: string[],
    value:
      | Array<string | number | boolean | ChoiceType>
      | Array<Array<string | number | boolean | ChoiceType>>,
  ) => void;
  defaultChoiceList?: ChoiceType[];
}) {
  const [optionList, setOptionList] = useState<ChoiceType[]>([]);
  const ref = useRef<number | null>(null);

  useEffect(() => {
    setOptionList(defaultChoiceList || []);
  }, [defaultChoiceList]);

  const handleAddChoice = () => {
    setOptionList((prev) => [
      ...prev,
      {
        id: nanoid(),
        name: `Option ${prev.length + 1}`,
        order: prev.length,
        default: false,
        imageUrl: '',
        imageKey: '',
      },
    ]);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setOptionList((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over?.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  useEffect(() => {
    if (ref.current) {
      onChange(['options'], [optionList]);
    }
    ref.current = 1;
  }, [optionList]);

  return (
    <section className="w-full choice-builder-wrapper">
      <Flex vertical gap={16} align="flex-start">
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={optionList}
            strategy={verticalListSortingStrategy}
          >
            {map(optionList, (option) => (
              <ChoiceCard
                questionId={questionId}
                item={option}
                initialDataList={optionList}
                onItemUpdate={(updatedList) => {
                  setOptionList(updatedList);
                }}
                onUploading={(toggle: boolean) => {
                  onUploading(toggle);
                }}
              />
            ))}
          </SortableContext>
        </DndContext>
        <CommonButton type="primary" icon={<Plus />} onClick={handleAddChoice}>
          Add Option
        </CommonButton>
      </Flex>
    </section>
  );
}
