import { useMutation } from '@apollo/client';
import {
  CheckoutEventNames,
  Environments,
  initializePaddle,
  Paddle,
  PaddleEventData,
} from '@paddle/paddle-js';
import Paragraph from 'antd/es/typography/Paragraph';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
  SubscriptionPlan,
  SubscriptionPurchaseStatus,
} from '../../__generated__/graphql';
import { COMPANY_INFO, DEFAULT_MONTH_FORMAT } from '../../common/constants';
import { handleGraphQlError, handleGraphQlSuccess } from '../../common/utils';
import CommonCancelAlertModal from '../../components/common/CommonCancelAlertModal';
import PurchaseSuccessModal from '../../components/common/PurchaseSuccessModal';
import UpgradeSuccessful from '../../components/common/UpgradeSuccessful';
import CommonButton from '../../components/primitives/CommonButton';
import {
  CANCEL_SUBSCRIPTION,
  PURCHASE_SUBSCRIPTION,
} from './graphql/mutations';

let timeout: NodeJS.Timeout | null = null;

type PaddleCheckoutType = {
  onPurchaseSuccess: () => void;
  onSubscriptionCancel: () => void;
  plan: SubscriptionPlan;
  showCancelBtn?: boolean;
  workspaceId: string;
};

export function PaddleCheckout({
  onPurchaseSuccess,
  onSubscriptionCancel,
  plan,
  showCancelBtn = false,
  workspaceId,
}: PaddleCheckoutType) {
  let paddleObj: Paddle | undefined;
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [timerModal, setTimerModal] = useState<boolean>(false);
  const [upgradeModal, setUpgradeModal] = useState<boolean>(false);

  const {
    workspaceSubscriptionStatus: planStatus,
    id,
    workspaceSubscriptionId,
    subscriptionRenewalDate,
  } = plan;

  const [purchaseSubscriptionMutate, { loading }] = useMutation(
    PURCHASE_SUBSCRIPTION,
    {
      context: {
        headers: {
          'x-workspace-id': workspaceId,
        },
      },
      onError: (res) => {
        handleGraphQlError(res?.message);
      },
    },
  );

  const [cancelSubscriptionMutate, { loading: cancelLoading }] = useMutation(
    CANCEL_SUBSCRIPTION,
    {
      context: {
        headers: {
          'x-workspace-id': workspaceId,
        },
      },
      onError: (res) => {
        handleGraphQlError(res?.message);
      },
    },
  );

  useEffect(() => {
    initializePaddle({
      environment: process.env.REACT_APP_PADDLE_ENVIRONMENT as Environments,
      token: process.env.REACT_APP_PADDLE_TOKEN!,
      eventCallback: (event: PaddleEventData) => {
        if (event.name === CheckoutEventNames.CHECKOUT_COMPLETED) {
          paddleObj?.Checkout.close();
          if (timeout) {
            clearTimeout(timeout);
          }
          setTimerModal(true);
          timeout = setTimeout(() => {
            setTimerModal(false);
            setUpgradeModal(true);
          }, COMPANY_INFO.purchaseSuccessDuration);
        }
      },
    })
      .then((paddleInstance: Paddle | undefined) => {
        if (paddleInstance) {
          paddleObj = paddleInstance;
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error('Error initializing paddle =>', e);
      });
  }, []);

  const handleOpenConfirmModal = () => {
    setConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(false);
  };

  // Callback to open a checkout
  const handlePayClick = () => {
    purchaseSubscriptionMutate({
      variables: {
        data: {
          subscriptionId: id!,
        },
      },
      onCompleted: (res) => {
        const transactionId = res.purchaseSubscription?.data?.transactionId;
        if (transactionId) {
          paddleObj?.Checkout.open({
            transactionId,
          });
        }
      },
    });
  };

  // Callback to open a checkout
  const handleCancelClick = () => {
    cancelSubscriptionMutate({
      variables: {
        where: {
          workspaceSubscriptionId: workspaceSubscriptionId!,
        },
      },
      onCompleted: (res) => {
        if (res.cancelSubscriptionPlan?.success) {
          handleGraphQlSuccess(res.cancelSubscriptionPlan.message);
          onSubscriptionCancel();
          handleCloseConfirmModal();
        }
      },
    });
  };

  const handleUpgradeSuccess = () => {
    setUpgradeModal(false);
    onPurchaseSuccess();
  };

  if (planStatus === SubscriptionPurchaseStatus.Active && showCancelBtn) {
    return (
      <>
        {subscriptionRenewalDate && (
          <Paragraph className="mb-0 text-content-secondary medium">
            {`Next renewal scheduled on ${dayjs(subscriptionRenewalDate)?.format(DEFAULT_MONTH_FORMAT)}.`}
          </Paragraph>
        )}
        <CommonButton
          size="middle"
          type="text"
          onClick={handleOpenConfirmModal}
          disabled={cancelLoading}
        >
          Cancel Subscription
        </CommonButton>
        {confirmModal && (
          <CommonCancelAlertModal
            isVisible={confirmModal}
            onCancel={handleCloseConfirmModal}
            onConfirm={handleCancelClick}
            okButtonProps={{
              loading: cancelLoading,
            }}
          />
        )}
      </>
    );
  }

  if (planStatus === SubscriptionPurchaseStatus.Active && !showCancelBtn) {
    return null;
  }

  return (
    <>
      <CommonButton type="primary" onClick={handlePayClick} loading={loading}>
        Upgrade now
      </CommonButton>
      {timerModal && <PurchaseSuccessModal isVisible={timerModal} />}
      {upgradeModal && (
        <UpgradeSuccessful
          isVisible={upgradeModal}
          onClose={handleUpgradeSuccess}
        />
      )}
    </>
  );
}
