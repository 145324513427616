import { Flex, SwitchProps, Tooltip } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { COMING_SOON_TEXT } from '../../../../common/constants';
import PremiumLogo from '../../../../components/common/PremiumLogo';
import CommonSwitch from '../../../../components/primitives/CommonSwitch';
import { FeatureName } from '../../../../types/common.type';

type CommonCheckLayout = {
  title: string;
  description: string;
  checked: boolean;
  isPremium?: boolean;
  onChange: (value: boolean) => void;
  switchProps?: SwitchProps;
  featureName: FeatureName;
};

export default function CommonCheckLayout({
  title,
  description,
  checked,
  isPremium,
  onChange,
  switchProps,
  featureName,
}: CommonCheckLayout) {
  return (
    <Flex gap={16} justify="space-between" align="flex-start">
      <Flex vertical gap={2}>
        <Flex gap={6} align="center">
          {title && (
            <Paragraph className="mb-0 text-base medium text-content-primary">
              {title}
            </Paragraph>
          )}
          {isPremium && <PremiumLogo isTooltip featureName={featureName} />}
        </Flex>
        <Paragraph className="mb-0 text-content-tertiary medium">
          {description}
        </Paragraph>
      </Flex>
      <Tooltip title={isPremium ? COMING_SOON_TEXT : ''}>
        <CommonSwitch
          type="primary"
          checked={checked}
          onChange={(val) => onChange(val)}
          {...switchProps}
        />
      </Tooltip>
    </Flex>
  );
}
